html, body {
  margin: 0;
  padding: 0;
}

.ps__rail-x {
  // top: 110px !important;
  @media only screen and (max-width: 600px) {
    // ?top: 90px !important;
  }
  .ps__thumb-x {
    //bottom: auto !important;
  }
}

.ps__rail-y {
}